import React from 'react';
import SideMenu from './SideMenu';


function Dashboard() {
  return (
    <div className="dashboard-wrapper">
        <SideMenu currentTab='dashboard' />
        <div className="main-content"></div>
    </div>
  );
}

export default Dashboard;