import React, { useState } from 'react';
import OutgoingFriendRequest from './OutgoingFriendRequest';

export default function OutgoingFriends(props) {
    
    const [data, setData] = useState(props.data.data().OutgoingFriendRequests);

    function updateFriends(newData) {
        setData(newData)
    }

    return (
        <div className="friends-section-wrapper">
            <h1>Outgoing friend requests:</h1>
            {data.map((friend, i) => {
                return <OutgoingFriendRequest updateFriends={updateFriends} key={i} index={i} id={props.data.id} friendId={friend}/>
            })}
        </div>
    )
}
