import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAT5KOF9wQbH23naKpVjkyKyra3bA4zq5k",
//     authDomain: "travel-expenses-6fff4.firebaseapp.com",
//     projectId: "travel-expenses-6fff4",
//     storageBucket: "travel-expenses-6fff4.appspot.com",
//     messagingSenderId: "136928141318",
//     appId: "1:136928141318:web:1fb7e494f1b9e2069f2387",
//     measurementId: "G-MDTL94BWHQ"
// })

// export const auth = app.auth();
// export default app;



// v2

const firebaseConfig = {
    apiKey: "AIzaSyAT5KOF9wQbH23naKpVjkyKyra3bA4zq5k",
    authDomain: "travel-expenses-6fff4.firebaseapp.com",
    projectId: "travel-expenses-6fff4",
    storageBucket: "travel-expenses-6fff4.appspot.com",
    messagingSenderId: "136928141318",
    appId: "1:136928141318:web:1fb7e494f1b9e2069f2387",
    measurementId: "G-MDTL94BWHQ"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export default app;