import React, { useState } from 'react';
import FriendRequest from './FriendRequest';

export default function IncomingFriends(props) {
    
    const [data, setData] = useState(props.data.data().IncomingFriendRequests);

    function updateFriends(newData) {
        setData(newData)
    }

    return (
        <div className="friends-section-wrapper">
            <h1>Pending friend requests:</h1>
            {data.map((friend, i) => {
                return <FriendRequest updateFriends={updateFriends} key={i} index={i} id={props.data.id} friendId={friend}/>
            })}
        </div>
    )
}
