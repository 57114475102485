import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './SideMenu';
import { useAuth } from '../../contexts/AuthContext';
import { getDoc, doc, collection, getDocs, query, where, limit, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';

export default function Friends() {

    const { currentUser } = useAuth();
    let user = currentUser.uid;
    
    return (
        <div className="dashboard-wrapper">
            <SideMenu currentTab={'groups'} />
            <div className="main-content">

            </div>
        </div>
    )
}
