import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { setDoc, doc } from 'firebase/firestore';

export default function Signup() {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const nameRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        }

        setError('');
        setLoading(true);

        signup(emailRef.current.value, passwordRef.current.value)
        .then((userCredentials) => {
            const user = userCredentials.user;
            setDoc(doc(db, 'users', user.uid), {
                Email: emailRef.current.value,
                Name: nameRef.current.value,
                Friends: [],
                IncomingFriendRequests: [],
                OutgoingFriendRequests: []
            })
            navigate('/');
        })
        .catch(error => {
            setError('Failed to create an account')
        }) 
        setLoading(false);

    }

    return (
        <div className="auth-page-wrapper">
        <div className="sign-up-card">
            <h2 className="auth-header">Sign Up</h2>
            {error && <div className="error">{error}</div>}
            <form onSubmit={handleSubmit} className="auth-form sign-up-form">
            <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Name</label>
                    <input type="text" ref={nameRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Email</label>
                    <input type="email" ref={emailRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password</label>
                    <input type="password" ref={passwordRef} className="auth-form-input" required/>
                </div>
                <div className="auth-form-group">
                    <label htmlFor="" className="auth-form-label">Password Confirmation</label>
                    <input type="password" ref={passwordConfirmRef} className="auth-form-input" required/>
                </div>
                <button disabled={loading} type="submit" className="button-stretch">Sign Up</button>
            </form>
        </div>
        <div className="switch-auth-domain">
            Already have an account? <Link style={{ color: 'var(--primary-clr)' }} to='/login'>Log in</Link>
        </div>
        </div>
    )
}
