import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, collection, updateDoc, arrayRemove, arrayUnion  } from 'firebase/firestore';
import { db } from '../../../firebase';
import bin from '../../../images/bin.png';
import profilePicture from '../../../images/profile-picture.png';

export default function CurrentFriend(props) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    useEffect(() => {
        getDoc(doc(db, 'users', props.friendId))
        .then((doc) => {
            setData(doc.data());
        })
        .catch(error => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [])

    if (loading) return "Loading...";
    if (error) return "Error!";

    function handleDeleteFriend(index, friendId) {
        
        let friends = []
        getDoc(doc(db, 'users', props.id))
        .then((doc) => {
            friends = doc.data().Friends;
            friends.splice(index, 1);
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                Friends: arrayRemove()
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                Friends: friends
            })
            .then(() => {
                props.updateFriends(friends)
            })

        })

        getDoc(doc(db, 'users', friendId))
        .then((doc) => {
            friends = doc.data().Friends;
            friends.splice(index, 1);
        })
        .then(() => {
            updateDoc(doc(db, 'users', friendId), {
                Friends: arrayRemove()
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', friendId), {
                Friends: friends
            })
            .then(() => {
                props.updateFriends(friends)
            })

        })
    }

    return (
        <div className="current-friend-card">
            <div className="current-friend-left-info">
                <div style={{ backgroundImage: `url('${profilePicture}')` }} className="current-friend-profile-picture"></div>
                <div className="current-friend-info">
                    <h3 className="current-friend-name">{data.Name}</h3>
                    <p className="current-friend-email muted">{data.Email}</p>
                </div>
            </div>
            <button onClick={handleDeleteFriend.bind(null, props.index, props.friendId)} disabled={loading} style={{ border: 'none' }} className="current-friend-remove">
                <img src={bin} alt="" className="current-friend-edit-icon" />
            </button>
        </div>
    )
}
