import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import settings from '../../images/settings.svg';
import dashboard from '../../images/dashboard.svg';
import card from '../../images/card.svg';
import friends from '../../images/friends.svg';
import logoutImg from '../../images/logout.svg';

export default function SideMenu(props) {

    const [logOutError, setLogOutError] = useState('');
    const { logout } = useAuth();
    const navigate = useNavigate();

    function checkForActiveLink(link) {
        if (link === props.currentTab) return 'active';
    }

    function handleLogout() {
        setLogOutError('');

        logout()
        .then(() => {
            navigate('/login');
        })
    }

    return (
        <div className="side-menu">
            <h2 className="logo">TravelBudgetting</h2>
            <div className="menu-collection">
                <h4 className="menu-collection-title">MENU</h4>
                <Link to='/' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
                <div className={'menu-link-wrapper ' + checkForActiveLink("dashboard")}>
                    <div className="menu-link-content-wrapper">
                        <img src={dashboard} alt="" className="menu-link-icon" />
                        <p className="menu-link-text">Dashboard</p>
                    </div>
                    <div className="active-link-indicator"></div>
                </div>
                </Link>
                <Link to='/groups' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
                <div className={'menu-link-wrapper ' + checkForActiveLink("groups")}>
                    <div className="menu-link-content-wrapper">
                        <img src={card} alt="" className="menu-link-icon" />
                        <p className="menu-link-text">My Groups</p>
                    </div>
                    <div className="active-link-indicator"></div>
                </div>
                </Link>
                <Link to='/friends' style={{ color: 'var(--muted)', textDecoration: 'none' }} >
                <div className={'menu-link-wrapper ' + checkForActiveLink("friends")}>
                    <div className="menu-link-content-wrapper">
                        <img src={friends} alt="" className="menu-link-icon" />
                        <p className="menu-link-text">Friends</p>
                    </div>
                    <div className="active-link-indicator"></div>
                </div>
                </Link>
            </div>
            <div className="menu-collection">
                <h4 className="menu-collection-title">OTHER</h4>
                <div className={'menu-link-wrapper ' + checkForActiveLink("settings")}>
                    <div className="menu-link-content-wrapper">
                        <img src={settings} alt="" className="menu-link-icon" />
                        <p className="menu-link-text">Settings</p>
                    </div>
                    <div className="active-link-indicator"></div>
                </div>
                <div onClick={handleLogout} className="menu-link-wrapper">
                    <div className="menu-link-content-wrapper">
                        <img src={logoutImg} alt="" className="menu-link-icon" />
                        <p className="menu-link-text">Logout</p>
                    </div>
                    <div className="active-link-indicator"></div>
                    {logOutError && <div className="error">{logOutError}</div>}
                </div>
            </div>
        </div>
    )
}
