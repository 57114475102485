import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './SideMenu';
import CurrentFriends from './friends/CurrentFriends';
import OutgoingFriends from './friends/OutgoingFriends';
import IncomingFriends from './friends/IncomingFriends';
import { useAuth } from '../../contexts/AuthContext';
import { getDoc, doc, collection, getDocs, query, where, limit, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';

export default function Friends() {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [addFriendError, setAddFriendError] = useState(null);
    const [addFriendSuccess, setAddFriendSuccess] = useState(null);
    const emailRef = useRef()

    const { currentUser } = useAuth();
    let user = currentUser.uid;
    
    useEffect(() => {
        getDoc(doc(db, 'users', user))
        .then((doc) => {
            setData(doc);
        })
        .catch(error => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [])

    if (loading) return "Loading...";
    if (error) return "Error!";

    function sendFriendRequest(sender, receiver) {

        console.log("Sending friend request")
        let outgoingrequests = sender.data().OutgoingFriendRequests;
        updateDoc(doc(db, "users", sender.id), {
            OutgoingFriendRequests: arrayRemove()
        })
        .then(() => {
            outgoingrequests.push(receiver.id);
            updateDoc(doc(db, "users", sender.id), {
                OutgoingFriendRequests: outgoingrequests
            })
            .catch(error => {
                console.error(error);
            })
        })
        .catch(error => {
            console.error(error);
        })

        let incomingrequests = receiver.data().IncomingFriendRequests;
        updateDoc(doc(db, "users", receiver.id), {
            IncomingFriendRequests: arrayRemove()
        })
        .then(() => {
            incomingrequests.push(sender.id);
            updateDoc(doc(db, "users", receiver.id), {
                IncomingFriendRequests: incomingrequests
            })
            .catch(error => {
                console.error(error);
            })
        })
        .catch(error => {
            console.error(error);
        })

        setAddFriendSuccess(`Send ${sender.Name} a friend request`);
    }

    function addFriend(e) {
        setAddFriendError(null);
        e.preventDefault();

        let email = emailRef.current.value;
        getDocs(query(collection(db, "users"), where("Email", "==", email), limit(1)))
        .then((docs) => {
            if (!docs.empty) {
                docs.forEach((doc) => {
                    if (doc.id !== data.id) {
                        if (!data.data().Friends.includes(doc.id)) {
                            if (!data.data().IncomingFriendRequests.includes(doc.id)) {
                                if (!data.data().OutgoingFriendRequests.includes(doc.id)) {
                                    sendFriendRequest(data, doc)
                                } else setAddFriendError(`You already send ${doc.data().Name} a friend request.`)
                            } else setAddFriendError(`You have a pending friend request from ${doc.data().Name}`)
                        } else setAddFriendError(`${doc.data().Name} already is your friend`)
                    } else setAddFriendError("You can'd send yourself a friend request!")
                })
            } else setAddFriendError('There is no user with this email')
        })
        .catch(error => {
            setAddFriendError(error)
            console.error("Error adding friend: ", error);
        })
    }
    
    return (
        <div className="dashboard-wrapper">
            <SideMenu currentTab={'friends'} />
            <div className="main-content">
                <form onSubmit={addFriend} className="add-friend">
                    <label htmlFor="" className="label">Add friend by email address</label>
                    <div className="form-input-wrapper">
                        <input ref={emailRef} className="form-input" type="text" />
                        <button type="submit" className="primary-button">Add Friend</button>
                    </div>
                    {addFriendError && <div className="error">{addFriendError}</div>}
                    {addFriendSuccess && <div className="success">{addFriendSuccess}</div>}
                </form>
                <div className="friends-content-wrapper">
                    <CurrentFriends data={data} />
                    <IncomingFriends data={data}/>
                    <OutgoingFriends data={data}/>
                </div>
            </div>
        </div>
    )
}
