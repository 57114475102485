import React from 'react';
import { BrowserRouter as Router, Routes, Route,  } from "react-router-dom";
import { AuthProvider } from '../contexts/AuthContext';
import PrivateRoute from './PrivateRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import Dashboard from './app/Dashboard';
import Friends from './app/Friends';
import Groups from './app/Groups';
import Signup from './authentication-flow/Signup';
import Login from './authentication-flow/Login';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path='/signup' element={ <AuthenticatedRoute><Signup /></AuthenticatedRoute> } ></Route>
                    <Route path='/login' element={ <AuthenticatedRoute><Login /></AuthenticatedRoute> } ></Route>
                    
                    <Route exact path='/' element={ <PrivateRoute><Dashboard /></PrivateRoute> } ></Route>
                    <Route exact path='/friends' element={ <PrivateRoute><Friends /></PrivateRoute> } ></Route>
                    <Route exact path='/groups' element={ <PrivateRoute><Groups /></PrivateRoute> } ></Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
  }
  
  export default App;
