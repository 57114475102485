import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc, collection, updateDoc, arrayRemove, arrayUnion  } from 'firebase/firestore';
import { db } from '../../../firebase';
import bin from '../../../images/bin.png';
import check from '../../../images/check.png';
import profilePicture from '../../../images/profile-picture.png';

export default function FriendRequest(props) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    
    useEffect(() => {
        getDoc(doc(db, 'users', props.friendId))
        .then((doc) => {
            setData(doc.data());
        })
        .catch(error => {
            console.error("Error fetching data: ", error);
            setError(error);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [])

    if (loading) return "Loading...";
    if (error) return "Error!";

    function handleDeleteFriend(index) {
        
        let friends = []
        getDoc(doc(db, 'users', props.id))
        .then((doc) => {
            friends = doc.data().Friends;
            friends.splice(index, 1);
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                Friends: arrayRemove()
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                Friends: friends
            })
            .then(() => {
                props.updateFriends(friends)
            })
        })
    }

    function handleAcceptFriendRequest(index, friendId) {

        console.log(friendId)
        let friendrequests = [];
        let friends = [];
        getDoc(doc(db, 'users', props.id))
        .then((doc) => {
            friends = doc.data().Friends;
            friends.push(friendId);
            friendrequests = doc.data().IncomingFriendRequests;
            friendrequests.splice(index, 1);
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                IncomingFriendRequests: arrayRemove()
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                IncomingFriendRequests: friendrequests
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', props.id), {
                Friends: arrayRemove()
            })
        })
        .then(() => {
            console.log(friendId)
            console.log(friends)
            updateDoc(doc(db, 'users', props.id), {
                Friends: friends
            })
        })

        getDoc(doc(db, 'users', friendId))
        .then((doc) => {
            friends = doc.data().Friends;
            friends.push(props.id);
            friendrequests = doc.data().OutgoingFriendRequests;
            friendrequests.splice(index, 1);
        })
        .then(() => {
            updateDoc(doc(db, 'users', friendId), {
                OutgoingFriendRequests: arrayRemove()
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', friendId), {
                OutgoingFriendRequests: friendrequests
            })
        })
        .then(() => {
            updateDoc(doc(db, 'users', friendId), {
                Friends: arrayRemove()
            })
        })
        .then(() => {
            console.log(friendId)
            console.log(friends)
            updateDoc(doc(db, 'users', friendId), {
                Friends: friends
            })
        })


    }

    return (
        <div className="current-friend-card">
            <div className="current-friend-left-info">
                <div style={{ backgroundImage: `url('${profilePicture}')` }} className="current-friend-profile-picture"></div>
                <div className="current-friend-info">
                    <h3 className="current-friend-name">{data.Name}</h3>
                    <p className="current-friend-email muted">{data.Email}</p>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <button onClick={handleAcceptFriendRequest.bind(null, props.index, props.friendId)} disabled={loading} style={{ border: 'none' }} className="current-friend-accept">
                    <img src={check} alt="" className="current-friend-edit-icon" />
                </button>
                <button onClick={handleDeleteFriend.bind(null, props.index, props.friendId) } disabled={loading} style={{ border: 'none' }} className="current-friend-remove">
                    <img src={bin} alt="" className="current-friend-edit-icon" />
                </button>
            </div>
        </div>
    )
}
